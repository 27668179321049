@use '../base/variables' as v;
@use '../base/mixins' as m;
#map {
    height: 400px;
    @include m.tablet {
        height: 450px;
    }
    @include m.desktop {
        height: 500px;
    }
    #cd-zoom-in,
    #cd-zoom-out {
        height: 32px;
        width: 32px;
        cursor: pointer;
        margin-left: 30px;
        opacity: .7;
        font-size: 1.3em;
        background-color: #333;
        span {
            width: 100%;
            float: left;
            margin-top: 7px;
        }
        &:hover {
            opacity: 1;
        }
    }
    #cd-zoom-in {
        background-position: 50% 0;
        margin-top: 400px;
        margin-bottom: 1px;
    }
    #cd-zoom-out {
        background-position: 50% -32px;
    }
    .no-touch #cd-zoom-in:hover,
    .no-touch #cd-zoom-out:hover {
        opacity: 1;
    }
}