@use 'variables' as v;
@use 'mixins' as m;
.pt-4b {
    padding-top: 4rem !important;
}

.pb-4b {
    padding-bottom: 4rem !important;
}

.py-4b {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.pt-5b {
    padding-top: 5rem !important;
}

.pb-5b {
    padding-bottom: 5rem !important;
}

.py-5b {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.pt-6 {
    padding-top: 6rem !important;
}

.pb-6 {
    padding-bottom: 6rem !important;
}

.py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.pt-7 {
    padding-top: 7rem !important;
}

.pb-7 {
    padding-bottom: 7rem !important;
}

.py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}

@include m.tablet {
    .pt-8 {
        padding-top: 8rem !important;
    }
    .pb-8 {
        padding-bottom: 8rem !important;
    }
    .py-8 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important;
    }
    .pt-9 {
        padding-top: 9rem !important;
    }
    .pb-9 {
        padding-bottom: 9rem !important;
    }
    .py-9 {
        padding-top: 9rem !important;
        padding-bottom: 9rem !important;
    }
    .pt-10 {
        padding-top: 10rem !important;
    }
    .pb-10 {
        padding-bottom: 10rem !important;
    }
    .py-10 {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }
    .pt-11 {
        padding-top: 11rem !important;
    }
    .pb-11 {
        padding-bottom: 11rem !important;
    }
    .py-11 {
        padding-top: 11rem !important;
        padding-bottom: 11rem !important;
    }
    .pt-12 {
        padding-top: 12rem !important;
    }
    .pb-12 {
        padding-bottom: 12rem !important;
    }
    .py-12 {
        padding-top: 12rem !important;
        padding-bottom: 12rem !important;
    }
}