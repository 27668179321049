@use '../base/variables' as v;
@use '../base/mixins' as m;
body {
    &.error {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        h1 {
            font-size: 7.5em;
            margin: 15px 0px;
            font-weight: 700;
        }
        h2 {
            font-weight: 700;
        }
    }
}