@use '../base/variables' as v;
@use '../base/mixins' as m;
.pt-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    overflow: auto;
    opacity: 0;
}

.pt-page-current,
.no-js .pt-page {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.pt-page a {
    padding: 8px 15px;
    @include m.phone {
        padding: 10px 15px;
    }
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}