@use '../base/variables' as v;
@use '../base/mixins' as m;
.aboutme {
    .aboutme-title {
        h1 {
            span.underlined {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 10%;
                    left: 0;
                    width: 100%;
                    height: 25%;
                    opacity: 0.25;
                }
            }
        }
        h4 {
            font-family: var(--main-font);
        }
        .aboutme-texts {
            @include m.tablet {
                margin-left: 5%;
                width: 35%;
            }
        }
        .aboutme-image {
            background-position: center 100px;
            min-height: 500px;
            background-repeat: no-repeat;
            @include m.tablet {
                background-position: center -25%;
                min-height: initial;
            }
        }
    }
    &.light-scheme {
        .aboutme-title h1 span.underlined:after {
            background: var(--light-accent-color);
        }
        .aboutme-info {
            svg.waves-1 {
                fill: url(#light-waves-1 ) var(--light-main-color);
            }
            svg.waves-2 {
                fill: url(#light-waves-2) var(--light-main-color);
            }
            .aboutme-info-content:after {
                background: var(--light-main-color);
            }
        }
    }
    &.dark-scheme {
        .aboutme-title h1 span.underlined:after {
            background: var(--dark-accent-color);
        }
        .aboutme-info {
            svg.waves-1 {
                fill: url(#dark-waves-1 ) var(--dark-main-color);
            }
            svg.waves-2 {
                fill: url(#dark-waves-2) var(--dark-main-color);
            }
            .aboutme-info-content:after {
                background: var(--dark-main-color);
            }
        }
    }
    .aboutme-info {
        font-size: 1.1em;
        letter-spacing: 0.025em;
        .row {
            position: relative;
        }
        svg {
            position: absolute;
            width: auto;
            padding: 0px;
            &.waves-1 {
                top: -10%;
                left: 0%;
            }
            &.waves-2 {
                bottom: 10%;
                right: 0%;
            }
        }
        .aboutme-info-content {
            position: relative;
            p {
                line-height: 1.3em;
            }
            &:last-child:after {
                display: none;
            }
            &:after {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                display: none;
                @include m.tablet {
                    display: block;
                }
            }
        }
    }
}

#light-waves-1 {
    --color-stop-1: rgba(var(--light-head-color-rgb), 1);
    --color-stop-2: rgba(var(--light-head-color-rgb), 0);
}

#light-waves-2 {
    --color-stop-1: rgba(var(--light-head-color-rgb), 0);
    --color-stop-2: rgba(var(--light-head-color-rgb), 1);
}

#dark-waves-1 {
    --color-stop-1: rgba(var(--dark-head-color-rgb), 1);
    --color-stop-2: rgba(var(--dark-head-color-rgb), 0);
}

#dark-waves-2 {
    --color-stop-1: rgba(var(--dark-head-color-rgb), 0);
    --color-stop-2: rgba(var(--dark-head-color-rgb), 1);
}